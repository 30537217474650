const tokens = {
  neutral: {
    backgroundColor: 'fill-tertiary-alt',
    borderColor: 'border-primary-default'
  },
  positive: {
    backgroundColor: 'fill-positive-default',
    borderColor: 'border-positive-default'
  },
  caution: {
    backgroundColor: 'fill-caution-default',
    borderColor: 'border-caution-default'
  },
  alert: {
    backgroundColor: 'fill-alert-default',
    borderColor: 'border-alert-default'
  },
  info: {
    backgroundColor: 'fill-info-default',
    borderColor: 'border-info-default'
  }
};

/**
 * This helper retrieves variant tokens for a particular variant.
 *
 * @param variant - The current variant of the StatusDot/StatusTag.
 *
 * @returns A new object containing tokens for the variants: neutral, positive, caution, alert, info.

 */
export const getVariantTokens = variant => {
  return tokens[variant];
};