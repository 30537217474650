import { css } from 'foundations-theming/utils';
export const linkStyles = ({
  theme,
  disabled,
  $isFolderNav = false
}) => {
  const paddingBlock = $isFolderNav ? theme.space['200'] : `calc(${theme.space['200']} - 1px)`;
  const left = $isFolderNav ? 0 : `-${theme.space['300']}`;
  const width = $isFolderNav ? '100%' : `calc(100% + ${theme.space['300']})`;
  return Object.assign({
    left,
    paddingBlock,
    width,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.color['text-core-default'],
    paddingInline: theme.space['300'],
    position: 'relative',
    textDecoration: 'none',
    cursor: disabled === true ? 'not-allowed' : 'pointer',
    transition: '0.15s all ease-out'
  }, theme.typography['body-100']);
};
export const activeStyles = css(["color:", ";background-color:", ";border-radius:", ";&::before{content:'';border-left:", " solid ", ";height:100%;left:0;position:absolute;top:0;border-radius:", ";}"], ({
  theme
}) => theme.color['text-core-default'], ({
  theme
}) => theme.color['fill-secondary-subtle'], ({
  theme
}) => theme.borderRadius['100'], ({
  theme
}) => theme.borderWidth['300'], ({
  theme
}) => theme.color['fill-secondary-default'], ({
  theme
}) => theme.borderRadius['100']);
export const hoveredStyles = ({
  theme,
  $isFolderNav = false
}) => {
  return Object.assign({}, !$isFolderNav && {
    backgroundColor: theme.color['fill-secondary-subtle'],
    borderRadius: theme.borderRadius['100']
  }, {
    color: theme.color['text-core-default'],
    textDecoration: 'none'
  });
};
export const disabledStyles = ({
  theme
}) => ({
  color: theme.color['text-primary-disabled'],
  textDecoration: 'none',
  cursor: 'not-allowed'
});
export const parentStyles = {
  userSelect: 'none'
};