import { useTheme } from 'foundations-theming/utils';
export const useVariantCssObject = (variantTokens, hollow) => {
  const theme = useTheme();
  return {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: hollow ? 'transparent' : theme.color[variantTokens.backgroundColor],
    border: hollow ? `2px solid theme.color[${variantTokens.borderColor}]` : 'initial'
  };
};