import PortalIdParser from 'PortalIdParser';
const PORTAL_ID = PortalIdParser.get();

// Learn more links
export const MARKETING_ACADEMY_URL = `/academy/${PORTAL_ID}/lessons/583/1471?tourId=academy-tour`;
export const CONTACTS_TOUR_URL = `/contacts/${PORTAL_ID}/contacts/list/view/all?tourId=contacts-and-contact-record-explore-1`;
export const MARKETING_STARTER_APP_MARKETPLACE_URL = 'https://ecosystem.hubspot.com/marketplace/apps/?eco_tiers=MARKETING_STARTER';
export const SALES_DATA_ACADEMY_URL = `/academy/${PORTAL_ID}/lessons/365/1988?tourId=academy-tour`;
export const MOBILE_APP_KB_URL = `https://knowledge.hubspot.com/mobile/how-to-install-the-hubspot-mobile-application`;
export const FREE_APP_MARKETPLACE_URL = 'https://ecosystem.hubspot.com/marketplace/apps/?eco_tiers=HUBSPOT_FREE';
export const SERVICE_CATEGORIES_APP_MARKETPLACE_URL = 'https://ecosystem.hubspot.com/marketplace/apps/?eco_category=CUSTOMER_SUCCESS&eco_category=HELP_DESK&eco_category=KNOWLEDGE_BASE&eco_category=LIVE_CHAT&eco_category=TICKETING';
export const SALES_STARTER_APP_MARKETPLACE_URL = 'https://ecosystem.hubspot.com/marketplace/apps/?eco_tiers=SALES_STARTER';
export const SERVICE_ACADEMY_URL = `/academy/${PORTAL_ID}/lessons/340/1822?tourId=academy-tour`;
export const SERVICE_SHARED_INBOX_TICKETS_KB_URL = 'https://knowledge.hubspot.com/get-started/support-your-customers';
export const HELP_DESK_KB_URL = 'https://knowledge.hubspot.com/help-desk/manage-and-respond-to-customer-tickets-in-help-desk';
export const HELP_DESK_APP_MARKETPLACE_URL = 'https://ecosystem.hubspot.com/marketplace/apps/help-desk';
export const CMS_ACADEMY_URL = `/academy/${PORTAL_ID}/lessons/1054824/5082`;
export const CMS_SOLUTION_MARKETPLACE_URL = `/ecosystem/${PORTAL_ID}/marketplace/solutions/website-development`;
export const COMMERCE_OVERVIEW_TOOLS_URL = `/commerce/${PORTAL_ID}`;
export const COMMERCE_PAYMENTS_LESSON_URL_QA = `/academy/${PORTAL_ID}/lessons/17692699/1002672`;
export const COMMERCE_PAYMENTS_LESSON_URL_PROD = `/academy/${PORTAL_ID}/lessons/1022818/4750`;
export const COMMERCE_CONNECT_STRIPE_URL = `/ecosystem/${PORTAL_ID}/marketplace/apps/commerce/payment-gateways/stripedemo`;