import { useEffect, useState } from 'react';
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { getDefaultHome } from 'setup-guide-api/api/navConfigApi';
import { useUserCreatedAt } from 'setup-guide-api/dataFetchingClient/queries/users';
import { useGlobalHomeEligibility } from 'setup-guide-api/dataFetchingClient/queries/globalHomeEligibility';
import { getSuperstoreValue, reportSuperstoreError } from '../utils/superstore';
import { getHubInfo } from '../api/portalApi';
import { HAS_CLOSED_CHANGE_HOMEPAGE_MODAL } from '../constants/modalConstants';
export const useTaskGroupModal = () => {
  const [activeGroupKey, setActiveGroupKey] = useState();
  const showTaskGroupModal = newTaskGroupKey => setActiveGroupKey(newTaskGroupKey);
  const hideTaskGroupModal = () => setActiveGroupKey(undefined);
  return {
    activeGroupKey,
    showTaskGroupModal,
    hideTaskGroupModal
  };
};
function changeDefaultHomepageModalIsDisabledViaSelenium() {
  try {
    return window.localStorage.getItem('selenium.disable.change-homepage-modal') === 'true';
  } catch (error) {
    return false;
  }
}
export const useShouldSeeChangeDefaultHomepageModal = () => {
  const [shouldSeeModalData, setShouldSeeModalData] = useState({
    hasDetermined: false,
    shouldSeeModal: false
  });
  const isDisabledViaSelenium = changeDefaultHomepageModalIsDisabledViaSelenium();
  const {
    userCreatedAt
  } = useUserCreatedAt();
  const {
    isEligibleForGlobalHome,
    loading: isLoadingGlobalHome,
    hasGlobalHomeError
  } = useGlobalHomeEligibility();
  useEffect(() => {
    if (isLoadingGlobalHome) {
      return;
    }
    if (isEligibleForGlobalHome || hasGlobalHomeError) {
      setShouldSeeModalData({
        shouldSeeModal: false,
        hasDetermined: true
      });
      return;
    }
    if (userCreatedAt) {
      // make sure the user is more than 90 days old
      const ninetyDaysInMilliseconds = 1000 * 60 * 60 * 24 * 90;
      const daysSinceUserCreationInMilliseconds = Date.now() - userCreatedAt;
      if (!isDisabledViaSelenium && daysSinceUserCreationInMilliseconds > ninetyDaysInMilliseconds) {
        getHubInfo().then(({
          createdAt: hubCreatedAt
        }) => {
          // Make sure the portal is at least 90 days old (to protect against invited users with older accounts seeing this modal in new portals)
          const daysSincePortalCreationInMilliseconds = Date.now() - hubCreatedAt;
          if (daysSincePortalCreationInMilliseconds > ninetyDaysInMilliseconds) {
            // find out if the user has previously dismissed the modal
            getSuperstoreValue(HAS_CLOSED_CHANGE_HOMEPAGE_MODAL).then(hasDismissed => {
              // only continue if the user has not previously dismissed the modal
              if (!hasDismissed) {
                // find out if the user has previously set a homepage
                getDefaultHome().then(({
                  path
                }) => {
                  // if the user has set a homepage that isn't user-guide, we want to display the modal
                  setShouldSeeModalData({
                    shouldSeeModal: !path || path.includes('user-guide'),
                    hasDetermined: true
                  });
                }).catch(captureNetworkError);
              } else {
                setShouldSeeModalData({
                  shouldSeeModal: false,
                  hasDetermined: true
                });
              }
            }).catch(error => reportSuperstoreError(HAS_CLOSED_CHANGE_HOMEPAGE_MODAL, 'get', error));
          } else {
            setShouldSeeModalData({
              shouldSeeModal: false,
              hasDetermined: true
            });
          }
        }).catch(captureNetworkError);
      }
    }
  }, [isDisabledViaSelenium, userCreatedAt, isEligibleForGlobalHome, isLoadingGlobalHome, hasGlobalHomeError]);
  return shouldSeeModalData;
};