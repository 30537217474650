import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { getUser } from '../../api/usersApi';
export const USER_DATA_QUERY = registerQuery({
  fieldName: 'user',
  fetcher: getUser
});
export const useUserCreatedAt = () => {
  const _useQuery = useQuery(USER_DATA_QUERY),
    {
      data
    } = _useQuery,
    queryResult = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  const userCreatedAt = data ? data.user.createdAt : null;
  return Object.assign({
    userCreatedAt
  }, queryResult);
};