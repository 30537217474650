import { isNested } from '../../utils/select-actionsmenu';

/**
 * Safely escape any special regex characters in a string so that the string
 * can be treated as a literal when used in a regular expression.
 * The regex expression matches special regex characters and then adds a backslash
 * before each of them to make then literal characters.
 */
export const escapeRegExp = text => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Get base padding values of the reference element, aka the trigger element
 * that toggles the menu. Note that these values may be overwritten
 * but other styles that are set after these base ones are applied.
 */
export const getReferenceBasePadding = theme => {
  return {
    paddingBlock: theme.space[200],
    paddingInline: theme.space[300]
  };
};

/**
 * Returns the CSS styles responsible for each variant style supported
 * by the reference element.
 */
export const getReferenceVariantStyles = (theme, referenceVariant) => {
  switch (referenceVariant) {
    case 'default':
      {
        return {
          backgroundColor: theme.color['fill-surface-raised'],
          border: `${theme.space[25]} solid ${theme.color['border-primary-default']}`,
          borderRadius: theme.borderRadius[200],
          '&:hover': {
            border: `${theme.space[25]} solid ${theme.color['border-primary-subtle']}`
          }
        };
      }
    case 'on-dark':
      {
        return {
          backgroundColor: theme.color['fill-surface-default'],
          border: `${theme.space[25]} solid ${theme.color['border-primary-default']}`,
          borderRadius: theme.borderRadius[200],
          '&:hover': {
            border: `${theme.space[25]} solid ${theme.color['border-primary-subtle']}`
          }
        };
      }

    // case 'property': {
    //   return {
    //     backgroundColor: theme.color['fill-surface-default'],
    //     border: 'none',
    //     borderBottom: `${theme.space[25]} solid ${theme.color['border-primary-default']}`,
    //     paddingInlineStart: theme.space[0],

    //     '&:hover': {},
    //   };
    // }

    default:
      {
        const __exhaustiveCheck = referenceVariant;
        throw new Error(`Unhandled \`referenceVariant\`:\n ${referenceVariant}`);
      }
  }
};

/**
 * Get the height value of the search input that's rendered in the menu.
 */
export const getTextInputHeight = theme => theme.space[1000];

/**
 * Normalize text by doing the following:
 * 1. Convert text to lowercase
 * 2. Decompse any accented characters into their base characters and combining diacritical marks
 * 3. Remove any combining diacritical marks
 */
export const normalizeText = text => {
  return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
export const getFlattenedOptions = optionsArray => {
  return optionsArray.flatMap(currentOption => {
    if (isNested(currentOption)) {
      const {
        subheading,
        options
      } = currentOption;
      return [{
        text: subheading,
        // subheadings are not a selectable option, so Value does not need to match
        value: subheading,
        isSubheading: true
      }, ...options.map((option, index) => Object.assign({}, option, {
        divider: index === options.length - 1
      }))];
    } else {
      return [Object.assign({}, currentOption)];
    }
  });
};