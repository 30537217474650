export const FEATURE_SWITCH_APPS = {
  USER_GUIDE: 'setup-guide-ui'
};
export const FeatureSwitch = ({
  allowedApps,
  disallowedApps,
  children,
  fallback = null,
  skipFeatureLoad
}) => {
  var _hubspot;
  if (skipFeatureLoad === true) {
    return fallback;
  }
  const currentProject = (_hubspot = window.hubspot) === null || _hubspot === void 0 || (_hubspot = _hubspot.bender) === null || _hubspot === void 0 ? void 0 : _hubspot.currentProject;
  if (Array.isArray(allowedApps)) {
    return allowedApps.includes(currentProject) ? children : fallback;
  }
  if (Array.isArray(disallowedApps)) {
    return disallowedApps.includes(currentProject) ? fallback : children;
  }
  return fallback;
};