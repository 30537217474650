import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import { parse, stringify } from 'hub-http/helpers/params';
import { getFullUrl } from 'hubspot-url-utils';
const IS_QA = enviro.isQa();
const PORTAL_ID = PortalIdParser.get();
export const getQueryString = queryParams => {
  if (!queryParams) {
    return window.location.search.slice(1);
  }
  return stringify(queryParams);
};
export const getQueryParam = key => parse(getQueryString())[key] || '';
export const APP_BASE_URL = getFullUrl('app');
export const getAppLink = (prefix, suffix = '') => {
  return `${APP_BASE_URL}/${prefix}/${PortalIdParser.get()}/${suffix}`;
};
export const getMailchimpImporterLink = (via = 'user-guide-data-sync-modal') => `/email/${PortalIdParser.get()}/import/mailchimp?via=${via}`;
export const getQuickImportUrl = (via = 'user-guide-data-sync-modal') => `/import/${PortalIdParser.get()}/new-import/standard?onboarding=true&via=${via}`;
export const getMarketPlaceOperationsTeamsUrl = () => `/ecosystem/${PortalIdParser.get()}/marketplace/apps/apps-for-operations-teams?via=user-guide`;

/* Academy urls */

export const ACADEMY_SUBDOMAIN_URL = `https://academy.hubspot.com`;
export const ACADEMY_URL = `/academy/${PORTAL_ID}`;
export const ACADEMY_APP_URL = getAppLink('academy', 'tracks');
export const ACADEMY_LESSON_CLOSING_SALES_URL = `${ACADEMY_URL}/lessons/427/2361`;
export const ACADEMY_LESSON_CONNECT_TEAM_EMAIL_URL = `${ACADEMY_URL}/lessons/340/1825`;
export const ACADEMY_LESSON_CONNECT_DOMAIN_URL = `${ACADEMY_URL}/lessons/407/2254`;
export const ACADEMY_LESSON_CONNECT_EMAIL_DOMAIN_URL = `${ACADEMY_URL}/lessons/409/2255`;
export const ACADEMY_LESSON_CONNECT_WITH_CONTACTS_URL = `${ACADEMY_URL}/lessons/426/2353`;
export const ACADEMY_LESSON_CONNECT_WITH_CONTACTS_TRACK_URL = `${ACADEMY_URL}/tracks/12/426/2353`;
export const ACADEMY_LESSON_CREATE_CAMPAIGN_URL = `${ACADEMY_URL}/lessons/237/1315`;
export const ACADEMY_LESSON_CREATE_CHATBOT_URL = `${ACADEMY_URL}/lessons/570/2869`;
export const ACADEMY_LESSON_CREATE_DEALS_URL = `${ACADEMY_URL}/tracks/12/427/2360`;
export const ACADEMY_LESSON_CREATE_FORM_URL = `${ACADEMY_URL}/lessons/429/2377`; // original: lessons/273/2379

export const ACADEMY_LESSON_CREATE_FORMS_URL = `${ACADEMY_URL}/lessons/429/2377`;
export const ACADEMY_LESSON_HELPDESK_BASICS = `${ACADEMY_URL}/lessons/342/3651`;
export const ACADEMY_LESSON_IDENTIFYING_LEADS_URL = `${ACADEMY_URL}/lessons/425/2347`;
export const ACADEMY_LESSON_IDENTIFYING_LEADS_TRACK_URL = `${ACADEMY_URL}/tracks/12/425/2347`;
export const ACADEMY_LESSON_IMPLEMENT_SALES_PROCESS_URL = `${ACADEMY_URL}/lessons/365/1990`;
export const ACADEMY_LESSON_IMPORT_CONTACTS_URL = `${ACADEMY_URL}/lessons/192/1876`;
export const ACADEMY_LESSON_INBOUND_MARKETING_URL = `${ACADEMY_APP_URL}/1028124/intro`; // original: 23/199/1659, alternative: lessons/1024819/

export const ACADEMY_LESSON_INBOUND_SERVICE_URL = `${ACADEMY_URL}/lessons/225/1200`;
export const ACADEMY_LESSON_MANAGING_PROPERTIES_URL = `${ACADEMY_URL}/lessons/366/1991`;
export const ACADEMY_LESSON_MASTERING_SALES_HUB_URL = `${ACADEMY_APP_URL}/44/intro`;
export const ACADEMY_LESSON_SALES_MANAGEMENT_URL = `${ACADEMY_APP_URL}/36/137/526`;
export const ACADEMY_LESSON_SERVICE_HUB_URL = `${ACADEMY_APP_URL}/97/intro`;
export const ACADEMY_LESSON_SETUP_MEETING_URL = `${ACADEMY_URL}/lessons/100/2398`;
export const ACADEMY_LESSON_START_MARKETING_URL = `${ACADEMY_URL}/tracks/60/intro`; // original: lessons/273/1472

export const ACADEMY_LESSON_SETTING_UP_URL = `${ACADEMY_URL}/lessons/339/1872`;
export const ACADEMY_LESSON_SETTING_UP_TICKETS_URL = `${ACADEMY_URL}/lessons/341/1823`;
export const ACADEMY_LESSON_SETTING_UP_TICKET_URL = `${ACADEMY_URL}/lessons/341/1831`;
export const ACADEMY_LESSON_SETTING_UP_TICKETS_ALT_URL = `${ACADEMY_URL}/lessons/341/1830`;
export const ACADEMY_LESSON_MARKETING_CONTACTS_URL = `${ACADEMY_URL}/lessons/${IS_QA ? '17693051/1003323' : '430/3980'}`;
export const ACADEMY_SALES_COACHING_FOR_MANAGERS_URL = `${ACADEMY_URL}/lessons/320/1770`;
export const ACADEMY_SALES_TRAINING_URL = `${ACADEMY_APP_URL}/${IS_QA ? '228' : '12'}/intro`;
export const ACADEMY_TRACKS_URL = `${ACADEMY_URL}/tracks`;
export const ACADEMY_INBOUND_SALES_URL = `${ACADEMY_APP_URL}/9/intro`;
export const ACADEMY_LANDING_PAGE_LESSON_URL = `${ACADEMY_URL}/lessons/39`;
export const ACADEMY_EMAIL_LESSON_URL = `${ACADEMY_URL}/lessons/473/1083`;
export const ACADEMY_COURSE_BLOGGING_TRAINING_URL = `${ACADEMY_SUBDOMAIN_URL}courses/blogging-training`;
export const ACADEMY_TRACKS_FREE_MARKETING_TOUR_URL = `${ACADEMY_TRACKS_URL}/60/471/1145?tourId=academy-tour`;
export const ACADEMY_FOR_MARKETING_INEXPERIENCED_URL = `${ACADEMY_URL}/lessons/472/1087`;
export const ACADEMY_FOR_SALES_LEADER_INEXPERIENCED_URL = `${ACADEMY_URL}/lessons/365/1987`;
export const ACADEMY_FOR_SERVICE_INEXPERIENCED_URL = `${ACADEMY_URL}/lessons/340/1822`;
export const ACADEMY_FREE_CMS_TOOLS_URL = `${ACADEMY_URL}/lessons/1054824/5082`;
export const ACADEMY_BUILD_WEBSITE_CMS_STARTER_URL = `${ACADEMY_TRACKS_URL}/1011126/1011849/4554`;
export const ACADEMY_CMS_FOR_MARKETERS_URL = `${ACADEMY_TRACKS_URL}/87/intro`;

/* Knowledge urls */

export const KNOWLEDGE_BASE_URL = 'https://knowledge.hubspot.com';
export const KNOWLEDGE_BASE_AUTOMATE_DEALS_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/deals/automate-tasks-on-deal-stages`;
export const KNOWLEDGE_BASE_AUTOMATE_TICKET_URL = `${KNOWLEDGE_BASE_URL}/tickets/automate-ticket-status`;
export const KNOWLEDGE_BASE_COMPANY_BRANDING_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/settings/customize-branding-for-your-sales-tools`;
export const KNOWLEDGE_BASE_CONNECT_DOMAIN_URL = `${KNOWLEDGE_BASE_URL}/cos-general/connect-a-domain-to-hubspot#connect-your-domain`;
export const KNOWLEDGE_BASE_CONNECT_EMAIL_DOMAIN_URL = `${KNOWLEDGE_BASE_URL}/cos-general/how-to-connect-your-email-sending-domain`;
export const KNOWLEDGE_BASE_CONNECT_FORM_TO_CONVERSATIONS_URL = `${KNOWLEDGE_BASE_URL}/conversations/connect-a-form-to-the-conversations-inbox`;
export const KNOWLEDGE_BASE_CONNECT_INBOX_URL = `${KNOWLEDGE_BASE_URL}/email-tracking/connect-your-inbox-to-hubspot`;
export const KNOWLEDGE_BASE_CONNECT_TEAM_EMAIL_URL = `${KNOWLEDGE_BASE_URL}/conversations/create-a-conversations-inbox#connect-a-team-email-channel`;
export const KNOWLEDGE_BASE_CONTACTS_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/contacts/manage-your-properties`;
export const KNOWLEDGE_BASE_CREATE_BOT_URL = `${KNOWLEDGE_BASE_URL}/chatflows/create-a-bot`;
export const KNOWLEDGE_BASE_CREATE_CAMPAIGN_URL = `${KNOWLEDGE_BASE_URL}/cos-general/get-started-with-the-marketing-email-tool`;
export const KNOWLEDGE_BASE_CREATE_CONTACTS_URL = `${KNOWLEDGE_BASE_URL}/contacts/create-contacts`;
export const KNOWLEDGE_BASE_CREATE_EMAIL_LIST_URL = `${KNOWLEDGE_BASE_URL}/lists/create-active-or-static-lists`;
export const KNOWLEDGE_BASE_CREATE_FORM_URL = `${KNOWLEDGE_BASE_URL}/forms/create-forms`;
export const KNOWLEDGE_BASE_CREATE_LIVE_CHAT_URL = `${KNOWLEDGE_BASE_URL}/chatflows/create-a-live-chat`;
export const KNOWLEDGE_BASE_CREATE_MEETING_URL = `${KNOWLEDGE_BASE_URL}/meetings-tool/create-a-meetings-link`;
export const KNOWLEDGE_BASE_CREATE_PROPERTY_URL = `${KNOWLEDGE_BASE_URL}/articles/kcs_article/contacts/how-can-i-create-a-custom-property`;
export const KNOWLEDGE_BASE_CREATE_TASK_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/tasks/use-tasks#create-tasks`;
export const KNOWLEDGE_BASE_CUSTOMIZE_TICKETS_URL = `${KNOWLEDGE_BASE_URL}/tickets/customize-ticket-pipelines-and-statuses`;
export const KNOWLEDGE_BASE_CREATE_TICKETS_URL = `${KNOWLEDGE_BASE_URL}/tickets/create-tickets`;
export const KNOWLEDGE_BASE_EMAIL_SUBSCRIPTION_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/contacts/how-do-subscription-preferences-and-types-work`;
export const KNOWLEDGE_BASE_EMAIL_TEMPLATES_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/conversations/create-and-send-templates`;
export const KNOWLEDGE_BASE_GET_STARTED_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/account/get-started-with-hubspot-crm`;
export const KNOWLEDGE_BASE_GET_STARTED_MARKETING_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/account/get-started-with-marketing-hub`;
export const KNOWLEDGE_BASE_GET_STARTED_SALES_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/account/get-started-with-sales-hub`;
export const KNOWLEDGE_BASE_GET_STARTED_SERVICE_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/account/get-started-with-service-hub`;
export const KNOWLEDGE_BASE_GET_STARTED_SUPPORT_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/get-started/support-your-customers`;
export const KNOWLEDGE_BASE_IMPORT_CONTACTS_URL = `${KNOWLEDGE_BASE_URL}/crm-setup/import-objects?_ga=2.258547594.1819331274.1587672556-854240470.1581428267&_gac=1.216778146.1587572059.EAIaIQobChMIlvD_m7b86AIVzJyzCh262wdaEAAYASAAEgJXs_D_BwE`;
export const KNOWLEDGE_BASE_INSTALL_MOBILE_APP_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/mobile/how-to-install-the-hubspot-mobile-application`;
export const KNOWLEDGE_BASE_INSTALL_SALES_URL = `${KNOWLEDGE_BASE_URL}/connected-email/how-to-install-hubspot-sales`;
export const KNOWLEDGE_BASE_INSTALL_TRACKING_CODE = `${KNOWLEDGE_BASE_URL}/reports/install-the-hubspot-tracking-code`;
export const KNOWLEDGE_BASE_INSTALL_WORDPRESS_PLUGIN = `${KNOWLEDGE_BASE_URL}/integrations/install-the-hubspot-wordpress-plugin`;
export const KNOWLEDGE_BASE_MANAGE_USERS_URL = `${KNOWLEDGE_BASE_URL}/settings/add-and-remove-users`;
export const KNOWLEDGE_BASE_MANAGE_PAID_SEATS_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/account/manage-sales-hub-and-service-hub-paid-users`;
export const KNOWLEDGE_BASE_RECORD_PROPERTIES_URL = `${KNOWLEDGE_BASE_URL}/articles/kcs_article/contacts/select-properties-to-show-under-the-about-section-in-a-record`;
export const KNOWLEDGE_BASE_SETUP_PIPELINE_AND_DEAL_URL = `${KNOWLEDGE_BASE_URL}/deals/set-up-and-customize-your-deal-pipelines-and-deal-stages`;
export const KNOWLEDGE_BASE_TASK_QUEUES_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/tasks/use-task-queues`;
export const KNOWLEDGE_BASE_USER_PERMISSIONS_GUIDE = `${KNOWLEDGE_BASE_URL}/settings/hubspot-user-permissions-guide`;
export const KNOWLEDGE_BASE_LANDING_PAGES_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/cms-general/get-started-with-landing-pages-and-website-pages`;
export const KNOWLEDGE_BASE_MARKETING_EMAIL_ARTICLE_URL = `${KNOWLEDGE_BASE_URL}/cos-general/get-started-with-the-marketing-email-tool`;
export const KNOWLEDGE_BASE_NOT_RECEIVE_EMAIL_URL = `${KNOWLEDGE_BASE_URL}/email/not-receiving-hubspot-system-or-notification-emails`;
export const KNOWLEDGE_BASE_TURN_ON_INBOX_AUTOMATION_URL = `${KNOWLEDGE_BASE_URL}/connected-email/connected-inbox-automation`;
/* Marketplace urls */

export const MARKETPLACE_URL = `/ecosystem/${PORTAL_ID}/marketplace/apps`;
export const MARKETPLACE_SOLUTIONS_URL = `/ecosystem/${PORTAL_ID}/marketplace/solutions`;
export const MARKETPLACE_MARKETING_TEAMS_URL = `${MARKETPLACE_URL}/marketing`;
export const MARKETPLACE_SALES_TEAMS_URL = `${MARKETPLACE_URL}/sales`;
export const MARKETPLACE_SERVICE_TEAMS_URL = `${MARKETPLACE_URL}/customer-service`;
export const MARKETPLACE_GMAIL_URL = `${MARKETPLACE_URL}/sales/sales-enablement/gmail`;
export const MARKETPLACE_MAILCHIMP_URL = `${MARKETPLACE_URL}/marketing/email/mailchimp`;
export const MARKETPLACE_EVENTBRITE_URL = `${MARKETPLACE_URL}/marketing/events/eventbrite`;
export const MARKETPLACE_OUTLOOK_URL = `${MARKETPLACE_URL}/sales/sales-enablement/office365`;
export const MARKETPLACE_GOOGLE_CALENDAR_URL = `${MARKETPLACE_URL}/sales/scheduling/google-calendar`;
export const MARKETPLACE_OUTLOOK_CALENDAR_URL = `${MARKETPLACE_URL}/sales/scheduling/office365-calendar`;
export const MARKETPLACE_FACEBOOK_MESSENGER_URL = `${MARKETPLACE_URL}/marketing/live-chat/facebook-messenger-204269`;
export const MARKETPLACE_SLACK_URL = `${MARKETPLACE_URL}/sales/sales-enablement/slack`;
export const MARKETPLACE_ZOOM_URL = `${MARKETPLACE_URL}/marketing/webinar/zoom`;
export const MARKETPLACE_DATA_SYNC_MAILCHIMP_URL = `${MARKETPLACE_URL}/marketing/email/mailchimp-data-sync`;
export const MARKETPLACE_DATA_SYNC_GOOGLE_CONTACTS_URL = `${MARKETPLACE_URL}/sales/sales-enablement/google-contacts-sync-232794`;
export const MARKETPLACE_DATA_SYNC_OUTLOOK_URL = `${MARKETPLACE_URL}/sales/sales-enablement/outlook-exchange-data-sync`;

/* Other urls */

export const CONTACT_TOUR_URL = `/contacts/${PORTAL_ID}/contact/1?tourId=contact-tour`;
export const CONTACTS_URL = `/contacts/${PORTAL_ID}/contacts/list/view/all`;
export const CONTACTS_TOUR_URL = `${CONTACTS_URL}?tourId=contacts`;
export const CONNECT_INBOX_URL = `/live-messages-settings/${PORTAL_ID}/onboarding?channelType=EMAIL`;
export const CREATE_CONTACT_TOUR_URL = `${CONTACTS_URL}?tourId=create-contact`;
export const HELP_DESK_CONNECT_EMAIL_URL = `/helpdesk-settings/${PORTAL_ID}/inboxes/email/create`;
export const LIVE_CHAT_URL = `/chatflows/${PORTAL_ID}/?limit=15&source=chat`;
export const TRAINING_AND_SERVICES_URL = `https://www.hubspot.com/services`;
export const USERS_AND_TEAMS_URL = `/settings/${PORTAL_ID}/users`;
export const ACCOUNT_AND_BILLING_URL = `/account-and-billing/${PORTAL_ID}/usage`;
export const DASHBOARD_MARKETING_URL = `/discover/${PORTAL_ID}/library/dashboards`;
export const QUICK_INVITE_MODAL_PATH = '/quick-invite';
export const QUICK_INVITE_MODAL_URL = `/user-guide/${PORTAL_ID}${QUICK_INVITE_MODAL_PATH}`;