import I18n from 'I18n';
import http from 'hub-http/clients/apiClient';
import { catchAndRethrowNetworkError } from 'growth-onboarding-reliability/utils/raven';
export const DISCOUNT = {
  STARTUPS_SEED: '75%',
  STARTUPS_SERIES_A: '50%',
  ENTREPRENEUR: '30%',
  BOOTSTRAP: '40%'
};
export const fetchEligibility = () => {
  return http.get(`/api/hsfx/v1/eligibility/portal-status`).catch(e => {
    catchAndRethrowNetworkError(e);
  });
};
export const isUpdatedInInterval = updatedAt => {
  const NOW = I18n.moment();
  const updatedAtMoment = I18n.moment(updatedAt);
  return NOW.diff(updatedAtMoment, 'days') >= 1;
};
export const isEligibleProgram = eligibility => {
  if (eligibility && DISCOUNT[eligibility.program] && eligibility.status === 'ELIGIBLE' && isUpdatedInInterval(eligibility.updatedAt)) {
    return true;
  }
  return false;
};

/**
 * Checks if sessionStorage is accessible in the current browser environment
 * @returns {boolean} True if sessionStorage can be accessed, false otherwise
 */
export const isSessionStorageAccessible = () => {
  try {
    // Try to set and remove a test item
    const testKey = 'hsfx.reminder.sessionStorageTest';
    window.sessionStorage.setItem(testKey, 'test');
    window.sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};
export const shouldShowBanner = () => {
  let bannerPreviouslyClosed = false;
  try {
    if (isSessionStorageAccessible()) {
      bannerPreviouslyClosed = !!window.sessionStorage.getItem('hsfx.reminder.closed');
    }
  } catch (e) {
    return false;
  }
  return !bannerPreviouslyClosed;
};
export const fetchDiscount = () => {
  return fetchEligibility().then(eligibility => {
    if (!eligibility || !isEligibleProgram(eligibility)) {
      return null;
    }
    return DISCOUNT[eligibility.program];
  });
};
export const validCurrencyForReferral = () => ['£', '$', '€'].reduce((acc, el) => {
  const stringRepresentation = I18n.formatIntlCurrency(100);
  return stringRepresentation.includes(el) || acc;
}, false);