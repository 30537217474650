import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Raven from 'raven-js';
import { Metrics } from '../../utils/metrics';
const SetupMetrics = () => {
  const taskError = useSelector(state => state.task.error);
  const taskProgressError = useSelector(state => state.taskProgress.error);
  useEffect(() => {
    if (taskError) {
      Metrics.counter('load-tasks-failed').increment();
      const error = new Error('Failed to load tasks');
      error.cause = taskError;
      Raven.captureException(error, {
        extra: {
          message: error.message
        }
      });
    }
  }, [taskError]);
  useEffect(() => {
    if (taskProgressError) {
      Metrics.counter('load-tasks-progress-failed').increment();
      const error = new Error('Failed to load tasks progress');
      error.cause = taskProgressError;
      Raven.captureException(error, {
        extra: {
          message: error.message
        }
      });
    }
  }, [taskProgressError]);
  return null;
};
export default SetupMetrics;