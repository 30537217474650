import { rpcClient } from '../rpcClient/rpcClient';
import { getAssignedTasksForUserRpc } from '../../__generated__/chirp/com/hubspot/task/assignment/rpc/AssignedTasksRpc';
import { getEligibleUsersRpc, hasEligibleUsersRpc, searchEligibleUsersRpc } from '../../__generated__/chirp/com/hubspot/task/assignment/rpc/EligibleUsersRpc';
import { assignOnboardingTaskRpc, deleteTaskAssignmentByIdRpc, getAllRpc } from '../../__generated__/chirp/com/hubspot/task/assignment/rpc/TaskAssignmentRpc';
export const fetchAssignedTasks = async () => {
  const response = await rpcClient.call(getAssignedTasksForUserRpc, {});
  return response;
};
export const fetchEligibleUsersForTask = async ({
  taskId
}) => {
  const response = await rpcClient.call(getEligibleUsersRpc, {
    request: {
      taskId
    },
    // this call has an n+1 problem with the number of users
    // so we are temporarily increasing the timeout while we fix it
    timeout: 30000
  });
  return response;
};
export const assignTaskToUser = async ({
  taskId,
  userId
}) => {
  const response = await rpcClient.call(assignOnboardingTaskRpc, {
    request: {
      taskId,
      userId
    }
  });
  return response;
};
export const fetchAllAssignedTasks = async () => {
  const response = await rpcClient.call(getAllRpc, {});
  return response;
};
export const deleteTaskAssignment = async ({
  assignmentId
}) => {
  const response = await rpcClient.call(deleteTaskAssignmentByIdRpc, {
    request: {
      id: assignmentId
    }
  });
  return response;
};
export const searchEligibleUsers = request => rpcClient.call(searchEligibleUsersRpc, {
  request
});
export const hasEligibleUsers = request => rpcClient.call(hasEligibleUsersRpc, {
  request
});