import { useMemo } from 'react';
import ProjectStorage from 'project-storage';
import { useAuth } from './auth';
export const useStorage = () => {
  const auth = useAuth();
  return useMemo(() => {
    const growthOnboardingStorage = new ProjectStorage();
    growthOnboardingStorage.setup({
      project: 'growth-onboarding',
      portalId: auth.portal.portal_id,
      userId: auth.user.user_id
    });
    return growthOnboardingStorage;
  }, [auth]);
};
export const useStorageValue = key => {
  const growthOnboardingStorage = useStorage();
  if (!key) {
    return undefined;
  }
  return growthOnboardingStorage.getItem(key);
};