import { GroupKeys, ChildGroupKeys } from './task';
export let ExperimentKey;
(function (ExperimentKey) {
  ExperimentKey["TACQ_0002"] = "tacq-0002";
})(ExperimentKey || (ExperimentKey = {}));
export const ExperimentGroupVariants = {
  [ExperimentKey.TACQ_0002]: {
    control: 'control',
    variation: 'variation'
  }
};
// Before removing any of these filters, please ensure the associated variant/filtered child groups have been updated or removed via
// the Onboarding Tasks UI (https://tools.hubteamqa.com/getting-started-tasks/groups)
const TaskChildGroupExperimentFilterMap = {
  [ExperimentKey.TACQ_0002]: (taskData, variant, exposureLogger) => {
    const {
      childGroups,
      key
    } = taskData;
    if (key !== GroupKeys.STARTER_COMMERCE_PAYMENTS_GROUP) {
      return taskData;
    }
    exposureLogger === null || exposureLogger === void 0 || exposureLogger(ExperimentKey.TACQ_0002);
    const shouldRemoveControlGroup = variant === ExperimentGroupVariants[ExperimentKey.TACQ_0002].variation;
    const childGroupToRemove = shouldRemoveControlGroup ? ChildGroupKeys.COMMERCE_STARTER_CREATE_AND_ACCEPT_PAYMENTS_ONLINE : ChildGroupKeys.COMMERCE_SET_UP_COMMERCE_TOOLS;
    taskData.childGroups = childGroups.filter(childGroup => childGroup.key !== childGroupToRemove);
  }
};
export const getTaskChildGroupExperimentFilterMap = () => TaskChildGroupExperimentFilterMap;