import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data", "error"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { isEligibleForGlobalHome } from '../../api/globalHomeApi';
export const GLOBAL_HOME_ELIGIBILITY_QUERY = registerQuery({
  fieldName: 'globalHomeEligibility',
  fetcher: () => isEligibleForGlobalHome()
});
export const useGlobalHomeEligibility = () => {
  var _data$globalHomeEligi;
  const _useQuery = useQuery(GLOBAL_HOME_ELIGIBILITY_QUERY),
    {
      data,
      error
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({
    isEligibleForGlobalHome: data === null || data === void 0 || (_data$globalHomeEligi = data.globalHomeEligibility) === null || _data$globalHomeEligi === void 0 ? void 0 : _data$globalHomeEligi.isEligible,
    hasGlobalHomeError: Boolean(error)
  }, rest);
};