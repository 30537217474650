import { DataSyncOptionAppsConfigs } from '../constants/dataSyncConstants';
import { CopyPasteAppConfigs, CopyPasteDataSyncOptions } from '../constants/copyPasteConstants';
import { prefetchDataImportPage } from '../utils/prefetchDataImportPage';
export const useDataSyncOptions = () => {
  prefetchDataImportPage();
  // Add config to render data tile for Copy and Paste option
  Object.assign(DataSyncOptionAppsConfigs, CopyPasteAppConfigs);
  return {
    dataSyncOptions: CopyPasteDataSyncOptions
  };
};