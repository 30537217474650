import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { TASK_REQUEST_RECEIVED } from 'setup-guide-api/actions/ActionTypes';
import { fetchProgress, fetchTasks } from 'setup-guide-api/actions/TaskActions';
import { changeGroupKey } from 'setup-guide-api/api/taskApi';
import { updateSettings } from 'onboarding-settings-client/api/settings';
import { APP_STARTED, ONBOARDING_VIEW_ACTIVATED } from './ActionTypes';
import { getCacheTaskGroupData } from '../selectors/cacheData';
import { getEnrollDefaultGroupLink, redirectTo } from '../utils/urls';
import { mutateTrackingCodeTaskCopy } from '../utils/trackingCodeOrWordpressDynamicTask';
import { getGroupKeyOverride } from '../utils/tasks';
const tasksRequestOptions = {
  onFinished: tasksData => mutateTrackingCodeTaskCopy(tasksData),
  onError: error => {
    if (error && error.status === 404) {
      redirectTo(getEnrollDefaultGroupLink());
    }
  }
};
const fetchUserGuideTasks = groupKey => {
  return (dispatch, getState) => {
    const cacheTaskGroup = getCacheTaskGroupData(getState(), groupKey);
    if (cacheTaskGroup) {
      return dispatch({
        type: TASK_REQUEST_RECEIVED,
        payload: cacheTaskGroup,
        timestamp: Date.now()
      });
    }
    return dispatch(fetchTasks(groupKey || undefined, tasksRequestOptions));
  };
};
export const startApp = () => (dispatch, getState) => {
  const state = getState();
  const maybeGroupKeyOverride = state && state.onboarding ? getGroupKeyOverride(state.onboarding.onboardingSettings) : undefined;
  dispatch({
    type: APP_STARTED
  });
  dispatch(fetchUserGuideTasks(maybeGroupKeyOverride));
  dispatch(fetchProgress(maybeGroupKeyOverride));
};
export const reloadUserGuideTasks = groupKey => dispatch => {
  dispatch(fetchUserGuideTasks(groupKey));
  dispatch(fetchProgress(groupKey));
};
export const switchView = (viewGroupKey, hubType) => (dispatch, getState) => {
  const {
    task,
    auth
  } = getState();
  const userId = auth.user.user_id;
  dispatch({
    type: ONBOARDING_VIEW_ACTIVATED,
    selectedView: hubType
  });
  if (viewGroupKey && viewGroupKey !== task.groupKey) {
    dispatch(fetchUserGuideTasks(viewGroupKey));
    dispatch(fetchProgress(viewGroupKey));
    changeGroupKey(viewGroupKey, hubType, userId).catch(captureNetworkError);
  } else {
    updateSettings({
      onboardingSettings: {
        selectedView: hubType
      }
    }).catch(captureNetworkError);
  }
};