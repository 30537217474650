import { useHasAnyScope } from '../../../hooks/auth';
import { useShouldSeeProTrialsBanner } from '../../../hooks/trials/useShouldSeeProTrialsBanner';
export const BILLING_READ_SCOPES = ['billing-read', 'billing-read-restricted'];
export const useNavFooterItems = () => {
  // Invite Link Item
  const showInviteLink = useHasAnyScope(['users-write']);

  // Product Item
  const hasBillingPermissions = useHasAnyScope(BILLING_READ_SCOPES);
  const {
    isLoading: isLoadingTrials,
    shouldSeeProTrialsBanner
  } = useShouldSeeProTrialsBanner();
  const shouldSeeProductLink = hasBillingPermissions && !isLoadingTrials;
  return {
    shouldSeeNavFooter: showInviteLink || shouldSeeProductLink,
    showInviteLink,
    hasBillingPermissions,
    showTrialPickerLink: shouldSeeProTrialsBanner,
    isLoadingTrials
  };
};